<template>
  <div class="list-box">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button @click="addTable('')">添加</vxe-button>
      </template>
    </vxe-toolbar>
    <vxe-table :data="value"
               ref="xTable"
               v-model="value"
               :edit-config="{trigger: 'click', mode: 'row',showIcon:false}"
               @edit-actived="editActivedEvent">
      <vxe-table-column v-for="(config, index) in firstColumn"
                        :key="index"
                        v-bind="config">
        <template v-slot:default="{row,rowIndex}">
          <template v-if="config.field == 'files'">
            <FormUpload fileMsg=" "
                        :value="value.files"
                        @onGetFileList="(fileList) => onGetFileList(rowIndex,fileList)" />
          </template>
          <template v-else-if="!config.field">
            <div class="setting-btn">
              <div class="inline-block">
                <a v-for="(btn,k) in config.buttons.slice(0,1)"
                   :key="k"
                   :style='row.isOld && "color:#999999"'
                   type="text"
                   @click="
                  val =>
                    clickCell({
                      val: btn,
                      row,
                      config,
                      rowIndex
                    })
                ">{{ btn.name }}
                </a>
              </div>
              <el-divider direction="vertical"
                          v-if="config.buttons.length > 1"></el-divider>
              <el-dropdown @command="val => clickCell({ val, row:config ,rowIndex})"
                           v-if="config.buttons.length > 1">
                <span class="el-dropdown-link">
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(btn, k) in config.buttons.slice(1,config.buttons.length)"
                                    :key="k"
                                    :command="btn.code">{{ btn.name }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
          <template v-else>
            <span v-if="config.editRender && config.editRender.options ">{{setOptions(row[config.field],config.editRender.options)}}</span>
            <span v-else>{{row[config.field]}}</span>
          </template>
        </template>
      </vxe-table-column>
    </vxe-table>

  </div>

</template>

<script>
import FormUpload from '../../../../../../../components/form/components/form_upload.vue';
import request from '../../../../../../../utils/request';

const orgColumn = [
  {
    field: 'payTypeName',
    title: '支付方式',
    width: '200',
  },
  {
    field: 'payType',
    title: '支付方式',
    visible: false,
  },
  { field: 'code', title: '条目编码', minWidth: '100' },
  { field: 'itemTotalFee', title: '条目总金额', minWidth: '100' },
  { field: 'itemUsedFee', title: '已使用金额', minWidth: '100' },
  { field: 'itemFrozenFee', title: '冻结金额', minWidth: '100' },
  { field: 'itemAvailableBalance', title: '可用金额', minWidth: '100' },
  {
    field: 'adjustType',
    title: '调整类型',
    width: '200',
    editRender: {
      name: 'ElSelect',
    },
  },
  {
    field: 'adjustFee',
    title: '调整值',
    width: '200',
    editRender: {
      name: 'ElInputNumber',
    },
  },
  {
    field: 'remarks',
    title: '备注',
    width: '200',
    editRender: {
      name: 'ElInput',
      props: {
        type: 'textarea',
      },
    },
  },
  {
    field: 'files',
    title: '上传附件',
    width: '200',
  },
  {
    title: '操作',
    width: '100',
    fixed: 'right',
    buttons: [
      {
        name: '删除',
      },
    ],
  },
];
export default {
  name: 'NoticeManageTableForm',
  components: {
    FormUpload,
  },
  props: {
    value: Array,
  },
  data() {
    return {
      modalConfig: {}, // 弹框信息
      formConfig: {}, // 弹框数据
      formName: 'Form',
      firstColumn: orgColumn,
      modalType: '', // 弹框包含非包含
      firstSelect: [],
      feeAdjustType: [],
      payType: [],
      formData: {},
    };
  },
  async created() {
    this.feeAdjustType = await this.getDictCode('rebate_adust_type_for_manager');
    const columns = [];
    this.firstColumn.map((v) => {
      const rowData = v;
      if (v.field === 'adjustType') {
        rowData.editRender.options = this.feeAdjustType;
        rowData.editRender.optionProps = {
          value: 'dictCode',
          label: 'dictValue',
        };
      }
      if (v.field) this.formData[v.field] = null;
      if (v.field === 'files') this.formData[v.field] = [];
      columns.push(rowData);
      return rowData;
    });
    // this.firstColumn = [];
    this.firstColumn = columns;
  },
  methods: {
    clickCell({ rowIndex, row }) {
      if (row.isOld) {
        this.$message.warning('该条目无法删除');
        return false;
      }
      this.value.splice(rowIndex, 1);
    },
    onGetFileList(rowIndex, fileList) {
      this.value[rowIndex].files = fileList;
    },
    setOptions(val, options) {
      const value = options.filter((v) => v.dictCode === val)[0];
      return value ? value.dictValue : '';
    },
    // 获取数据字典数据
    async getDictCode(dictCode) {
      const res = await request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: dictCode,
      });
      return res.result;
    },
    async addTable() {
      this.formData.payTypeName = '折扣';
      this.formData.payType = '0';
      const record = this.formData;
      this.$refs.xTable.insert(record).then(({ row }) => {
        this.$refs.xTable.setActiveRow(row);
        this.$emit('addData', this.$refs.xTable.tableData);
      });
    },
    onGetSelect() {},
    editActivedEvent() {

    },
  },
};
</script>

<style lang="less" scoped>
.setting-btn {
  display: flex;
  align-items: center;
  color: #409eff;
  justify-content: center;
  width: 100%;
  .ellipsis {
    margin-right: 8px;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
  }
}
</style>
