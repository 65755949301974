<script>
import Form, { formCreate } from '../../../../../../components/form';
// import request from '../../../../../../utils/request';

import FrozenDetail from './components/frozen_detail_table.vue';

formCreate.component('FrozenDetail', FrozenDetail);

export default {
  extends: Form,
  data() {
    return {
      rule: [],
      buttons: {
        submit: false,
      },
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  async mounted() {
    this.rule = await this.getBeforFormRule('fee_frozen_detail_form');
    this.reload(this.rule);
    this.setValue({ list: this.formConfig.selectRow[0] });
  },
  methods: {
    closeModal() {
      this.$emit('onClose');
      this.$emit('onGetList');
    },
  },
};
</script>
