<script>
import TablePage from '../../../../../../components/table_page';
import configs from './data';
import {
  ManualEntryForm, AjustForm, Condition, ConditionDetail, TotalAdjust, FrozenDetail, TotalAdjustTwo, DiscountDetail, DiscountInfo,
} from '../form';
// import request from '../../../../../../utils/request';

export default {
  extends: TablePage,
  components: {
    ManualEntryForm,
    AjustForm,
    Condition,
    ConditionDetail,
    TotalAdjust,
    FrozenDetail,
    TotalAdjustTwo,
    DiscountDetail,
    DiscountInfo,
  },
  props: {
    parentCode: String,
  },
  data() {
    return {
      requestUrl: '',
      // params: { parentCode: '' },
      configs,
      formConfig: {},
      selection: [],
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  async created() {
    await this.getConfigList('fee_discount_cost_pool');
  },
  methods: {
    // beforeSearchEvent(val) {
    //   console.log(val.$event.type);
    // },
    modalClick({ val, row }) {
      if (val.code === 'adjustment') {
        this.modalConfig.title = '调整';
        this.formName = 'AjustForm';
        this.formConfig.selectRow = [row];
        this.openFull();
      } else if (val.code === 'manual_entry') {
        this.modalConfig.title = '手动上账';
        this.formName = 'ManualEntryForm';
        this.formConfig.selection = [];
        this.openModal();
      } else if (val.code === 'condition_detail') {
        this.modalConfig.title = '条目详情';
        this.formName = 'Condition';
        this.formConfig.selectRow = [row];
        this.openFull();
      } else if (val.code === 'condition_log') {
        this.modalConfig.title = '条目操作';
        this.formName = 'ConditionDetail';
        this.formConfig.selectRow = [row];
        this.openFull();
      } else if (val.code === 'total_adjust') {
        this.modalConfig.title = '总量调整';
        this.formName = 'TotalAdjustTwo';
        this.formConfig.btnCode = val.code;
        this.formConfig.poolCode = row.poolCode;
        this.openModal();
      } else if (val.code === 'dms_frozen') {
        this.modalConfig.title = '冻结';
        this.formName = 'TotalAdjust';
        this.formConfig.btnCode = 'frozen';
        this.formConfig.selectRow = [row];
        this.openFull();
      } else if (val.code === 'thaw') {
        this.modalConfig.title = '解冻';
        this.formName = 'TotalAdjust';
        this.formConfig.btnCode = val.code;
        this.formConfig.selectRow = [row];
        this.openFull();
      } else if (val.code === 'frozen_detail') {
        this.modalConfig.title = '冻结明细';
        this.formName = 'FrozenDetail';
        this.formConfig.btnCode = val.code;
        this.formConfig.selectRow = [row];
        this.openFull();
      } else if (val.code === 'discount_detail') {
        this.modalConfig.title = '';
        this.formName = 'DiscountDetail';
        this.modalFormData = row;
        this.openFull();
      } else if (val.code === 'getDetail') {
        this.modalConfig.title = '费用池详情';
        this.formName = 'DiscountInfo';
        this.modalFormData = row;
        this.openFull();
      }
      return true;
    },
  },
};
</script>
