<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

import { AdjustTable } from './components';

formCreate.component('AdjustTableDiscount', AdjustTable);

export default {
  extends: Form,
  data() {
    return {
      rule: [],
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  async mounted() {
    this.rule = await this.getBeforFormRule('fee_discount_adjust');
    this.rule.map((v) => {
      const rowData = v;
      if (rowData.field === 'rebateFeePoolDetailVos') {
        rowData.on = {
          ...rowData.on,
          addData: (val) => {
            this.setValue({ rebateFeePoolDetailVos: val });
          },
        };
      }
      rowData.props = {
        ...rowData.props,
        disabled: true,
      };
      return rowData;
    });

    this.reload(this.rule);
    // this.setValue(this.formConfig.selectRow[0]);
    this.getData();
  },
  methods: {
    getData() {
      request
        .post('/dms/dms/m/rebateFeePool/findDetailPageByConditions', { rebateFeePoolCode: this.formConfig.selectRow[0].code })
        .then((res) => {
          res.result.data.map((v) => {
            const rowData = v;
            rowData.payType = `${rowData.payType || 0}`;
            rowData.adjustType = `${rowData.adjustType || ''}`;
            rowData.remarks = '';
            rowData.files = [];
            rowData.isOld = true;
            return rowData;
          });
          this.setValue({
            cusName: this.formConfig.selectRow[0].cusName,
            cusCode: this.formConfig.selectRow[0].cusCode,
            rebateFeePoolDetailVos: res.result.data,
          });
        });
    },
    // 提交
    async submit() {
      const params = {
        ...this.formConfig.selectRow[0],
        ...this.getFormData(),
      };
      const path = '/dms/dms/m/rebateFeePool/adjust';
      // if (this.formConfig.selection[0]) {
      //   path = '/dms/m/companyInformation/edit';
      // } else {
      //   path = '/dms/m/companyInformation/add';
      // }
      // return false;
      request
        .post(path, params)
        .then((res) => {
          if (res.success) {
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      return true;
    },
  },
};
</script>
