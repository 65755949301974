var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-box" },
    [
      _c("vxe-toolbar", {
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "vxe-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.addTable("")
                      },
                    },
                  },
                  [_vm._v("添加")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: {
            data: _vm.value,
            "edit-config": { trigger: "click", mode: "row", showIcon: false },
          },
          on: { "edit-actived": _vm.editActivedEvent },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        },
        _vm._l(_vm.firstColumn, function (config, index) {
          return _c(
            "vxe-table-column",
            _vm._b(
              {
                key: index,
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        var rowIndex = ref.rowIndex
                        return [
                          config.field == "files"
                            ? [
                                _c("FormUpload", {
                                  attrs: {
                                    fileMsg: " ",
                                    value: _vm.value.files,
                                  },
                                  on: {
                                    onGetFileList: function (fileList) {
                                      return _vm.onGetFileList(
                                        rowIndex,
                                        fileList
                                      )
                                    },
                                  },
                                }),
                              ]
                            : !config.field
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "setting-btn" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "inline-block" },
                                      _vm._l(
                                        config.buttons.slice(0, 1),
                                        function (btn, k) {
                                          return _c(
                                            "a",
                                            {
                                              key: k,
                                              style:
                                                row.isOld && "color:#999999",
                                              attrs: { type: "text" },
                                              on: {
                                                click: function (val) {
                                                  return _vm.clickCell({
                                                    val: btn,
                                                    row: row,
                                                    config: config,
                                                    rowIndex: rowIndex,
                                                  })
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(btn.name) + " ")]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    config.buttons.length > 1
                                      ? _c("el-divider", {
                                          attrs: { direction: "vertical" },
                                        })
                                      : _vm._e(),
                                    config.buttons.length > 1
                                      ? _c(
                                          "el-dropdown",
                                          {
                                            on: {
                                              command: function (val) {
                                                return _vm.clickCell({
                                                  val: val,
                                                  row: config,
                                                  rowIndex: rowIndex,
                                                })
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "el-dropdown-link",
                                              },
                                              [
                                                _vm._v(" 更多"),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-down el-icon--right",
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "el-dropdown-menu",
                                              {
                                                attrs: { slot: "dropdown" },
                                                slot: "dropdown",
                                              },
                                              _vm._l(
                                                config.buttons.slice(
                                                  1,
                                                  config.buttons.length
                                                ),
                                                function (btn, k) {
                                                  return _c(
                                                    "el-dropdown-item",
                                                    {
                                                      key: k,
                                                      attrs: {
                                                        command: btn.code,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(btn.name) + " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                config.editRender && config.editRender.options
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.setOptions(
                                            row[config.field],
                                            config.editRender.options
                                          )
                                        )
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(_vm._s(row[config.field])),
                                    ]),
                              ],
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              "vxe-table-column",
              config,
              false
            )
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }